<template>
    <div class="AdminDetail">
        <main-header menuActive="admin" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>관리자</h2>
                </div>

                <div class="row">
                    <table class="table table-profile">
                        <colgroup>
                            <col width="10%">
                            <col width="40%">
                            <col width="10%">
                            <col width="40%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">구분</th>
                            <td class="text-left" colspan="3">
                              <label v-for="(data, index) in list_admin_code" :key="index"
                                     class="mrg-right-10"
                              >
                                <input type="radio"
                                       :value="data.code"
                                       v-model="f_admin_type"
                                />
                                {{data.name}}
                              </label>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">아이디</th>
                            <td class="text-left" colspan="3"><input type="text" class='input type2 width-100' v-model="f_id" /></td>
                        </tr>
                        <tr v-if="!f_idx">
                            <th class="field">비밀번호</th>
                            <td class="text-left"><input type="password" class='input type2 width-100' v-model="f_pw" /></td>
                            <th class="field">비밀번호 확인</th>
                            <td class="text-left"><input type="password" class='input type2 width-100' v-model="f_pw2" /></td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_admin_name"  /></td>
                            <th class="field">사용여부</th>
                            <td class="text-left">
                              <label v-for="(data, index) in list_use_code" :key="index"
                                     class="mrg-right-10"
                              >
                                <input type="radio"
                                       :value="data.code"
                                       v-model="f_use_yn"
                                />
                                {{data.name}}
                              </label>

                            </td>
                        </tr>
                        <tr>
                            <th class="field">부서</th>
                            <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_admin_part"  /></td>
                            <th class="field">연락처</th>
                            <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_admin_phone" v-mask="['###-###-####','###-####-####']" inputmode="numeric" /></td>
                        </tr>
                        <tr>
                            <th class="field">메모</th>
                            <td class="text-left" colspan="3"><input type="text" class='input type2 width-100' v-model="f_memo"  /></td>
                        </tr>
                        <tr>
                            <th class="field">시설</th>
                            <td class="text-left" colspan="3">
                              <select class="select_ansimi width150"
                                      v-model="f_fasility_code"
                                      :disabled="list_fasility.length == 1"
                              >
                                <option v-for="(data, index) in list_fasility" :key="index" :value="data.code">
                                  {{data.name}}
                                </option>
                              </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="listBackFunc()">목록</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';


export default {
    name: 'AdminDetail',
    data() {
        return {
            f_admin_type : 'G1103',      // 관리자 구분
            f_id : '',              // 아이디
            f_pw : '',              // 비번
            f_pw2 : '',             // 비번 확인
            f_admin_name : '',      // 관리자 이름
            f_use_yn : '',          // 사용여부
            f_admin_part : '',      // 관리자 부서
            f_admin_phone : '',     // 관리자 연락처
            f_memo : '',            // 메모
            f_fasility_code : '',   // 시설
            f_idx:'',               // 고유번호

            list_admin_code : [],              // 관리자 구분 리스트
            list_fasility : [],           // 시설 리스트
            list_fasility_org : [],             // 시설별 리스트
            list_use_code : [                  // 사용여부 리스트
                { code:'Y', name:'사용' },
                { code:'N', name:'정지' }
            ],
        }
    },
    components: { mainHeader, mainFooter },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
      f_admin_type(){
        this.listFasilitySetFunc()
      }
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
        this.$nextTick(function() {
            if (this.$route.params.data.fidx) {
                let params = `{"fIdx":${this.$route.params.data.fidx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocMember/detail?params=' + queryString, null, 'initInfo');
            }
            /* --- 기존방식 ---
            if(!this.storageGet('pageInfo')) this.storageSet('pageInfo', this.$route.params.data);

            let info = this.storageGet('pageInfo', 'info');

            if (info.f_idx){
                this.f_admin_type = info.f_admin_type_code;
                this.f_id = info.f_id;
                this.f_admin_name = info.f_admin_name;
                this.f_use_yn = info.f_use_yn;
                this.f_admin_part = info.f_admin_part;
                this.f_admin_phone = info.f_admin_phone;
                this.f_memo = info.f_memo;
                this.f_fasility_code = info.f_fasility_code;
                this.f_idx = info.f_idx;
            }
            */
        });
    },
    methods: {
        init(){
            // let formData = new FormData();
            // formData.append('api_name', 'patientCreateCode');

            this.ajaxGet('nocCommon/code?params=', null, 'initResult');
        },
        initResult(response){

            let result = response;

            // 구분
            this.list_admin_code = result.adminCodeList;
            this.list_admin_code.splice(this.list_admin_code.findIndex(item => item.code === 'G1106'), 1)

            // 시설
            this.list_fasility = result.fasilityList;
            this.list_fasility.unshift({ code:'', name:'선택' });

            // 시설별
            this.list_fasility_org = result.fasilityList;
            this.listFasilitySetFunc()
        },
        initInfo(response){
            let result = response.list;

            if(!this.storageGet('pageInfo')) this.storageSet('pageInfo', result);

            if (result) {
                this.f_admin_type = result.fadminType;
                this.f_id = result.fid;
                this.f_admin_name = result.fadminName;
                this.f_use_yn = result.fuseYn;
                this.f_admin_part = result.fadminPart;
                this.f_admin_phone = result.fadminPhone;
                this.f_memo = result.fmemo;
                this.f_fasility_code = result.ffasilityCode;
                this.f_idx = result.fidx;
            }
        },
        listFasilitySetFunc(){
            let vm = this;
            // console.log(vm);
            vm.list_fasility = [];
            this.list_fasility_org.forEach(function(obj){

                // 시생치
                if (vm.f_admin_type == 'G1103' && obj.upperCode == 'G0202'){
                  vm.list_fasility.push(obj)
                }

                // 구생치
                if (vm.f_admin_type == 'G1104' && obj.upperCode == 'G0201'){
                  vm.list_fasility.push(obj)
                }

                // 병원
                if (vm.f_admin_type == 'G1102' && obj.upperCode == 'G0203'){
                  vm.list_fasility.push(obj)
                }

            });
            this.list_fasility.unshift({ code:'', name:'선택' });
        },
        sendFunc(){

            if (!this.f_admin_type){
                this.showAlert('', '관리자 구분을 선택해 주세요.');
                return false;
            }

            if (!this.f_id){
                this.showAlert('', '아이디를 입력해 주세요.');
                return false;
            }

            if (!this.f_idx){
                if (!this.f_pw){
                    this.showAlert('', '비밀번호를 입력해 주세요.');
                    return false;
                }

                if (!this.f_pw2){
                    this.showAlert('', '비밀번호 확인을 입력해 주세요.');
                    return false;
                }

                if (this.f_pw != this.f_pw2){
                    this.showAlert('', '비밀번호를 다시 확인해 주세요.');
                    return false;
                }
            }

            if (!this.f_admin_name){
                this.showAlert('', '이름을 입력해 주세요.');
                return false;
            }

            if (!this.f_use_yn){
                this.showAlert('', '사용여부를 선택해 주세요.');
                return false;
            }

            if (!this.f_admin_part){
                this.showAlert('', '부서를 입력해 주세요.');
                return false;
            }

            if (!this.f_admin_phone){
                this.showAlert('', '연락처를 입력해 주세요.');
                return false;
            }

            // if (!this.f_fasility_code){
            //     this.showAlert('', '시설을 선택해 주세요.');
            //     return false;
            // }

            console.log(this.f_idx);
            let data = {
                // "fIdx": this.f_idx,
                "fAdminType" : this.f_admin_type,
                "fId" : this.f_id,
                "fPw" : this.f_pw,
                "fAdminName" : this.f_admin_name,
                "fAdminPhone" : this.f_admin_phone,
                "fAdminPart": this.f_admin_part,
                "fFasilityCode" : this.f_fasility_code,
                "fUseYn" : this.f_use_yn,
                "fAdminId" : this.userDataAdminId
            }
            if (this.f_idx) {
                data.fIdx = this.f_idx;
                console.log(data);
                this.ajaxPost('nocMember/update', data,'sendFuncResult');
            } else {
                console.log(data);
                this.ajaxPost('nocMember/regist', data,'sendFuncResult');
            }

            /*
            let formData = new FormData();
            if (this.f_idx){
                formData.append('api_name', 'adminUpdate');
                formData.append('f_idx', this.f_idx);
            }else{
                formData.append('api_name', 'adminCreate');
                formData.append('f_pw', this.f_pw);
            }
            formData.append('f_admin_type', this.f_admin_type);
            formData.append('f_id', this.f_id);
            formData.append('f_admin_name', this.f_admin_name);
            formData.append('f_use_yn', this.f_use_yn);
            formData.append('f_admin_part', this.f_admin_part);
            formData.append('f_admin_phone', this.f_admin_phone);
            formData.append('f_memo', this.f_memo);
            formData.append('f_fasility_code', this.f_fasility_code);
            formData.append('f_admin_id', this.userDataAdminId);
            */

        },
        sendFuncResult(response) {
            this.showAlert('', '저장 되었습니다.',"listBackFunc");
        },
        listBackFunc(){
            if (this.storageGet('pageInfo')) {
                this.$router.push({ name: 'AdminAdminList', params: this.storageGet('pageInfo')});
            } else {
                this.$router.push({ name: 'AdminAdminList', params: ""});
            }

        }
    },
    destroyed(){
        this.storageRemove('pageInfo');
    }
}
</script>

<style scoped>
</style>
